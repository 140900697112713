<script>
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import axios from "axios";
/**
 * Dashboard Component
 */
export default {
  
  props: ["idDataEdit"],

  page: {
    title: "Edit Lingkup Kegiatan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
  },
  data() {
    return {
      title: "Edit Lingkup Kegiatan",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Lingkup Kegiatan",
          href: "/master/Lingkup Kegiatan",
        },
        {
          text: "Edit Lingkup Kegiatan",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      // variable Page
      master_role: ["one", "two", "three"],
      role_selected: "",
      tsk_id: this.idDataEdit,
      lingkup: "",
    };
  },
  mounted() {
    this.getLingkup();
  },
  methods: {
    getLingkup() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/master/lingkup?id=" +
            self.tsk_id
        )
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.list_data.data[0];
          if (response_data.code == 200) {
            self.lingkup = response_data_fix.lingkup;
            //console.log(response_data_fix.lingkup);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
          Swal.close();
        });
    },
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("id", self.tsk_id);
      data.append("lingkup", self.lingkup);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/lingkup/update",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data; 
          if(response_data.code != 200){
              Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
              });
          }else{
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman master lingkup segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.$emit("refresh-table", "edit");
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <div class="row">
        <div class="col-lg-12">
            <b-form class="p-2" @submit.prevent="StoreData">
                <div class="row">
                    <div class="col-md-12">
                        <b-form-group class="mb-3" label="Lingkup Kegiatan" label-for="formrow-Lingkup Kegiatan-input">
                            <b-form-input id="formrow-Lingkup Kegiatan-input" type="text" v-model="lingkup"></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-end">
                        <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> Simpan</b-button>
                    </div>
                </div>
            </b-form>
        </div>
    </div>
</template>
